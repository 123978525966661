<template>
  <div>
    <app-bar currentPage="main"></app-bar>
    <div
      :style="{ height: `${windowHeight - 90}px` }"
      style="overflow-y: scroll"
      class="noScroll"
    >
      <v-form v-model="valid" class="d-flex align-center flex-column mb-3">
        <h3 class="white--text my-8">Reset Password:</h3>
        <v-text-field
          class="text-body-2"
          label="E-mail"
          v-model="email"
          outlined
          dense
          disabled
          dark
          :style="{
            borderRadius: '10px',
            width: `${displaySize(15, -1)}%`,
            minWidth: `15%`,
          }"
          required
        ></v-text-field>
        <v-text-field
          class="text-body-2"
          label="New Password"
          v-model="password1"
          outlined
          dense
          dark
          :type="passType1"
          :append-icon="passType1 === 'text' ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="togglePassType(1)"
          :counter="20"
          :rules="passwordRules"
          :style="{
            borderRadius: '10px',
            width: `${displaySize(15, -1)}%`,
            minWidth: `15%`,
          }"
          required
        ></v-text-field>
        <v-text-field
          class="text-body-2"
          label="Repeat Password"
          v-model="password2"
          outlined
          dense
          dark
          :type="passType2"
          :append-icon="passType2 === 'text' ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="togglePassType(2)"
          :counter="20"
          :rules="passwordRules"
          :style="{
            borderRadius: '10px',
            width: `${displaySize(15, -1)}%`,
            minWidth: `15%`,
          }"
          required
        ></v-text-field>
        <p
          :style="{ opacity: `${$store.getters['auth/error'] ? 1 : 0}` }"
          class="red--text font-weight-bold text-caption mb-3"
        >
          {{ $store.getters["auth/messageError"] }}&nbsp;
        </p>
        <loading-circle
          :position="'relative'"
          :size="40"
          v-if="$store.getters['auth/isLoading']"
        ></loading-circle>
        <v-btn
          v-else
          :disabled="!valid || $store.getters['auth/isLoading']"
          :dark="!valid || $store.getters['auth/isLoading']"
          class="text-capitalize"
          color="green"
          @click="resetPassword"
        >
          Reset Passowrd
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import LoadingCircle from "@/components/LoadingCircle.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: { AppBar, LoadingCircle },
  data: () => ({
    valid: false,
    email: "",
    code: "",
    passType1: "password",
    passType2: "password",
    password1: "",
    password2: "",
  }),
  computed: {
    passwordRules() {
      return this.$store.getters["auth/passwordRules"].concat([
        (v) => v[0] !== " " || "This must not start with space",
        (v) =>
          (v[0] !== " " && v[v.length - 1] !== " ") ||
          "This must not end with space",
      ]);
    },
  },
  created() {
    this.$store.commit("currentPage", { page: "main" });
    this.email = this.$route.query.email;
    this.code = this.$route.query.code;
  },
  methods: {
    togglePassType(number) {
      if (number === 1)
        this.passType1 = this.passType1 === "text" ? "password" : "text";
      else this.passType2 = this.passType2 === "text" ? "password" : "text";
    },
    resetPassword() {
      if (this.password1 !== this.password2) {
        this.$store.state.auth.messageError = "The passwords are not are same!";
        this.$store.state.auth.error = true;
        return;
      }
      const email = this.email;
      const password = this.password1;
      const code = this.code;
      this.$store.dispatch("auth/resetPassword", { email, code, password });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> label {
  font-size: 13px;
}

.v-text-field--outlined >>> button {
  font-size: 18px;
}
</style>