var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-bar',{attrs:{"currentPage":"main"}}),_c('div',{staticClass:"noScroll",staticStyle:{"overflow-y":"scroll"},style:({ height: ((_vm.windowHeight - 90) + "px") })},[_c('v-form',{staticClass:"d-flex align-center flex-column mb-3",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('h3',{staticClass:"white--text my-8"},[_vm._v("Reset Password:")]),_c('v-text-field',{staticClass:"text-body-2",style:({
          borderRadius: '10px',
          width: ((_vm.displaySize(15, -1)) + "%"),
          minWidth: "15%",
        }),attrs:{"label":"E-mail","outlined":"","dense":"","disabled":"","dark":"","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticClass:"text-body-2",style:({
          borderRadius: '10px',
          width: ((_vm.displaySize(15, -1)) + "%"),
          minWidth: "15%",
        }),attrs:{"label":"New Password","outlined":"","dense":"","dark":"","type":_vm.passType1,"append-icon":_vm.passType1 === 'text' ? 'mdi-eye-off' : 'mdi-eye',"counter":20,"rules":_vm.passwordRules,"required":""},on:{"click:append":function($event){return _vm.togglePassType(1)}},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}}),_c('v-text-field',{staticClass:"text-body-2",style:({
          borderRadius: '10px',
          width: ((_vm.displaySize(15, -1)) + "%"),
          minWidth: "15%",
        }),attrs:{"label":"Repeat Password","outlined":"","dense":"","dark":"","type":_vm.passType2,"append-icon":_vm.passType2 === 'text' ? 'mdi-eye-off' : 'mdi-eye',"counter":20,"rules":_vm.passwordRules,"required":""},on:{"click:append":function($event){return _vm.togglePassType(2)}},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}}),_c('p',{staticClass:"red--text font-weight-bold text-caption mb-3",style:({ opacity: ("" + (_vm.$store.getters['auth/error'] ? 1 : 0)) })},[_vm._v(" "+_vm._s(_vm.$store.getters["auth/messageError"])+"  ")]),(_vm.$store.getters['auth/isLoading'])?_c('loading-circle',{attrs:{"position":'relative',"size":40}}):_c('v-btn',{staticClass:"text-capitalize",attrs:{"disabled":!_vm.valid || _vm.$store.getters['auth/isLoading'],"dark":!_vm.valid || _vm.$store.getters['auth/isLoading'],"color":"green"},on:{"click":_vm.resetPassword}},[_vm._v(" Reset Passowrd ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }